<template>
  <div class="about">
    <aboutTab></aboutTab>

    <div  class="about-content">
      <aboutCont></aboutCont>
      <aboutCard></aboutCard>
      <aboutPartner></aboutPartner>
    </div>

  </div>
</template>

<script>
import aboutTab from '@/components/AboutTab.vue';
import aboutCard from '@/components/AboutCard.vue';
import aboutCont from '@/components/AboutCont.vue';
import aboutPartner from '@/components/AboutPartner.vue';

export default {
  name: 'AboutView',
  components: {
    aboutTab,
    aboutCont,
    aboutCard,
    aboutPartner,
  },
};
</script>

<style>
.about {
  position: relative;
  overflow: hidden;
}

.about-content {
  position: relative;
  transform: translateY(0);
  transition: transform 0.5s ease;
}

.aboutTab {
  position: relative;
  transform: translateY(0);
  transition: transform 0.5s ease;
}

.contact h2{
  text-decoration: none;
}
</style>
