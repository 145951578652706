<template>
    <div class="HomeB">
        <h1 class="HomeTittle" id="Server">{{ $t('ff.HomeBTittle') }}</h1>
        <p class="TextTittle"> {{ $t('ff.HomeBText') }} </p>
        <div class="Home PhotoGroup-B">
            <div class="Pro01" >
                <img width="600" height="600"
                    id="Photo1" src="../assets/data/Home/B/01.png" alt="Photo 1">
                <h1 class="Title"> {{ $t('ff.HomeBPhoto01Tittle') }} </h1>
                <p class="Text"> {{ $t('ff.HomeBPhoto01Text') }} </p>
            </div>

            <div class="Pro02">
                <img width="600" height="600"
                        id="Photo2" src="../assets/data/Home/B/02.png" alt="Photo 2" >
                <h1 class="Title"> {{ $t('ff.HomeBPhoto02Tittle') }} </h1>
                <p class="Text"> {{ $t('ff.HomeBPhoto02Text') }} </p>
            </div>

            <div class="Pro03">
                <img width="600" height="600"
                        id="Photo3" src="../assets/data/Home/B/03.png" alt="Photo 3">
                <h1 class="Title"> {{ $t('ff.HomeBPhoto03Tittle') }} </h1>
                <p class="Text"> {{ $t('ff.HomeBPhoto03Text') }} </p>
            </div>

            <div class="Pro04">
                <img width="600" height="600"
                        id="Photo4" src="../assets/data/Home/B/04.png" alt="Photo 4">
                <h1 class="Title"> {{ $t('ff.HomeBPhoto04Tittle') }} </h1>
                <p class="Text"> {{ $t('ff.HomeBPhoto04Text') }} </p>
            </div>

            <div class="Pro05">
                <img width="600" height="600"
                        id="Photo5" src="../assets/data/Home/B/05.png" alt="Photo 5">
                <h1 class="Title"> {{ $t('ff.HomeBPhoto05Tittle') }} </h1>
                <p class="Text"> {{ $t('ff.HomeBPhoto05Text') }} </p>
            </div>

            <div class="Pro06">
                <img width="600" height="600"
                        id="Photo6" src="../assets/data/Home/B/06.png" alt="Photo 6">
                <h1 class="Title"> {{ $t('ff.HomeBPhoto06Tittle') }} </h1>
                <p class="Text"> {{ $t('ff.HomeBPhoto06Text') }} </p>
            </div>
        </div>

        <button  class='more-more-HomeB'
            @click="scrollToBottom()">
                {{ $t('ff.HomeBButton') }}
        </button>
    </div>
</template>

<script>
import router from '@/router';

export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.2/vanilla-tilt.min.js';
        script.async = true;
        document.body.appendChild(script);
    },
    methods: {
        redirectToAnotherPage() {
            router.push('./Contact');
        },
        scrollToBottom() {
            window.scrollTo({
                top: document.body.scrollHeight - window.innerHeight,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style>
.HomeB {
    position: relative;
}

.HomeB .HomeTittle{
    font-size: 2.3vw;
    color: #9C91D4;
    padding: 0% 0% 1%;
}

.HomeB .TextTittle {
    font-size: 1.25vw;
    font-weight: bold;
    letter-spacing: 0.01em;
}

.HomeB .Home.PhotoGroup-B{
    padding: 1.85% 0%;
}

.HomeB .Pro01,.HomeB .Pro02,.HomeB .Pro03 ,.HomeB .Pro04,.HomeB .Pro05 ,.HomeB .Pro06 {
    background-color: #0F0C1F;
    display: inline-block;
    height: 25vw;
    width: 25vw;
    padding: 1vw 4vw 5vw;
    margin: 1vw;
    border-radius: 5%;
}

.HomeB .Pro01 .Title,.HomeB .Pro02 .Title,.HomeB .Pro03 .Title,
.HomeB .Pro04 .Title,.HomeB .Pro05 .Title,.HomeB .Pro06 .Title {
    position: relative;
    font-size: 1.75vw;
    margin: 0vw 0vw 1vw 0vw;
    color: #85CEC1;
}

.HomeB .Pro01 .Text,.HomeB .Pro02 .Text,.HomeB .Pro03 .Text,
.HomeB .Pro04 .Text,.HomeB .Pro05 .Text,.HomeB .Pro06 .Text {
    font-size: 1.1vw;
    height: 10vw;
    overflow: auto;
}

.HomeB #Photo1,.HomeB #Photo2,.HomeB #Photo3,.HomeB #Photo4,.HomeB #Photo5,.HomeB #Photo6{
    width: 5vw;
    height: 5vw;
    margin: 1vw 5vw 1vw 5vw;
}

.more-more-HomeB {
    color: #000000;
    font-size: 1.15vw;
    padding: 0.8vw 5vw;
    border-radius: 0.35vw;
    font-weight: bold;
    border: 1px solid #85CEC1;
    background-color:#85CEC1;
}

.more-more-HomeB:hover {
    box-shadow: 0 0 15px #85CEC1;
    cursor: pointer;
}

@media (max-width: 2400px) {
    .HomeB .Pro01, .HomeB .Pro02, .HomeB .Pro03, .HomeB .Pro04, .HomeB .Pro05, .HomeB .Pro06 {
        width: 23vw;
        height: 23vw;
    }
}
@media (max-width: 1200px) {
    .HomeB .Home.PhotoGroup-B{
        padding: 2.85% 1%;
    }
}
@media (max-width: 1000px) {
    .HomeB {
        position: relative;
        margin: 3vw 0vw 0vw 0vw;
    }
    .HomeB .Home.PhotoGroup-B{
        padding-top: 5vw;
    }
    .HomeB .HomeTittle{
        font-size: 6vw;
    }
    .HomeB .TextTittle {
        font-size: 3.25vw;
    }
    .HomeB .Text {
        font-size: 3.5vw;
    }
    .HomeB .Pro01, .HomeB .Pro02, .HomeB .Pro03, .HomeB .Pro04, .HomeB .Pro05, .HomeB .Pro06 {
        background-color: #0F0C1F;
        display: inline-block;
        width: 83vw;
        height: 76vw;
        padding: 3vw 2vw 5vw 2vw;
        margin: 3vw 1vw 1vw 1vw;
        border-radius: 10%;
        overflow:hidden;
    }
    .HomeB #Photo1, .HomeB #Photo2, .HomeB #Photo3, .HomeB #Photo4, .HomeB #Photo5, .HomeB #Photo6 {
        width: 15vw;
        height: 15vw;
    }
    .HomeB .Pro01 .Title, .HomeB .Pro02 .Title, .HomeB .Pro03 .Title,
    .HomeB .Pro04 .Title, .HomeB .Pro05 .Title, .HomeB .Pro06 .Title {
        font-size: 6.5vw;
        width: 86vw;
    }
    .HomeB .Pro01 .Text, .HomeB .Pro02 .Text, .HomeB .Pro03 .Text,
    .HomeB .Pro04 .Text, .HomeB .Pro05 .Text, .HomeB .Pro06 .Text {
        font-size: 4vw;
        height: 40vw;
        overflow: auto;
        padding: 3vw 7vw 0vw 7vw;
    }
    .more-more-HomeB {
        font-size: 3vw;
        padding: 2.5vw 8vw;
        margin: 8vw 0vw 0vw 0vw;
        border-radius: 2vw;
    }
}
</style>
