<template>
    <div class="HomeA">
            <h1 class="Tittle">{{ $t('ff.HomeATittle') }}</h1>
            <h1 class="Text"> {{ $t('ff.HomeAText') }} </h1>
            <div class="Home Photo">
                <div class="PhotoGroup-A">
                    <img width="157.491" height="157.491"
                             id="Photo1" src="../assets/data/Home/A/01.png" alt="Photo 1">
                    <img width="2000" height="2000"
                             id="Photo2" src="../assets/data/Home/A/02.png" alt="Photo 2">
                    <img width="2000" height="2000"
                             id="Photo3" src="../assets/data/Home/A/03.png" alt="Photo 3">
                </div>

                <div class="num-Text">
                    <div class="num">
                        <h1 id="Num01">100+</h1>
                        <h1 id="Num02">1,500,000+</h1>
                        <h1 id="Num03">1,000,000+</h1>
                    </div>

                    <div class="Text">
                        <h1 id="Text01">{{ $t('ff.HomeAText01') }}</h1>
                        <h1 id="Text02">{{ $t('ff.HomeAText02') }}</h1>
                        <h1 id="Text03">{{ $t('ff.HomeAText03') }}</h1>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            classifications: [],
        };
    },
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.2/vanilla-tilt.min.js';
        script.async = true;
        document.body.appendChild(script);
        fetch('./json/Home.json')
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to fetch data.json');
            })
            .then((data) => {
                this.classifications = data;
                console.log(this.classifications);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },
};
</script>

<style>
.HomeA {
    position: relative;
    height: 50vw;
    top: 7vw;
}
.HomeA .Home.Photo {
    position: relative;
}
.HomeA .Tittle{
    font-size: 2.3vw;
    color: #9C91D4;
    margin-bottom: 0.5vw;
}

.HomeA .Text{
    font-size: 1.25vw;
    font-weight: bold;
    letter-spacing: 0.01em;
}

.HomeA .PhotoGroup-A, .HomeA .num-Text .num, .HomeA .num-Text .Text{
    display: flex;
    justify-content: center;
}
.HomeA .PhotoGroup-A img {
    width: 15vw;
    height: 15vw;
    margin: 3vw 6vw 3vw;
}

.HomeA .num-Text .num h1{
    width: 14.5vw;
    margin: 0vw 6.5vw 1vw;
}

.HomeA .num-Text .Text h1{
    width: 14.5vw;
    margin: 0vw 6.5vw 3vw;
    font-size: 1.5vw;
}

.HomeA .Home.Photo .num{
    color: #85CEC1;
    font-size: 1vw;
}

@media (max-width: 1000px) {
    .HomeA {
        padding: 8vw 0vw 215vw 0vw;
        position: relative;
    }
    .HomeA .Tittle {
        font-size: 6vw;
    }
    .HomeA .Text{
        font-size: 3.5vw;
    }
    .HomeA .PhotoGroup-A img{
        margin: 5vw 6.5vw 13vw;
        width: 42vw;
        height: 42vw;
    }
    .HomeA #Photo3{
        padding: 7vw 0vw 0vw 0vw;
    }
    .HomeA .PhotoGroup-A, .HomeA .num-Text .num, .HomeA .num-Text .Text{
        display: block;
    }
    .HomeA .num-Text .num #Num01 {
        position: absolute;
        top: 46vw;
        left: 35vw;
        font-size: 6vw;
    }
    .HomeA .num-Text .num #Num02 {
        position: absolute;
        top: 111vw;
        left: 29vw;
        font-size: 6vw;
    }
    .HomeA .num-Text .num #Num03 {
        position: absolute;
        top: 170vw;
        left: 28vw;
        font-size: 6vw;
        padding: 7vw 0vw 0vw 0vw;
    }
    .HomeA .num-Text .Text #Text01 {
        position: absolute;
        top: 53vw;
        left: 25vw;
        font-size: 4vw;
        width: 41vw;
    }
    .HomeA .num-Text .Text #Text02 {
        position: absolute;
        top: 118vw;
        left: 25vw;
        font-size: 4vw;
        width: 41vw;
    }
    .HomeA .num-Text .Text #Text03 {
        position: absolute;
        top: 175vw;
        left: 25vw;
        font-size: 4vw;
        width: 41vw;
        padding: 8vw 0vw 0vw 0vw;
    }
}</style>
