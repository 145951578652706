import { createStore } from 'vuex';

export default createStore({
  state: {
    // 狀態
    projectState: ['全部'],
    classificationState: {},
    introductionData: null,
  },
  getters: {
    // 取值
    projectState: (state) => state.projectState,
    classificationState: (state) => state.classificationState,
    introductionData: (state) => state.introductionData,
  },
  mutations: {
    // 變化
    SET_PROJECTS_STATE(state, datas) {
      state.projectState = datas;
    },
    SET_CLASSIFICATION_STATE(state, data) {
      state.classificationState = data;
    },
    SET_INTRODUCTION_DATA(state, data) {
      state.introductionData = data;
    },
    CLEAR_INTRODUCTION_DATA(state) {
      state.introductionData = null;
    },
  },
  actions: {
    // 操作
    handleProjectsState({ commit }, datas) {
      commit('SET_PROJECTS_STATE', datas);
    },
    handleClassificationState({ commit }, data) {
      commit('SET_CLASSIFICATION_STATE', data);
    },
  },
  modules: {
    // 模組
  },
});
