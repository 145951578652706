<template>
  <homeTab></homeTab>
  <homeCont></homeCont>
  <homeProj></homeProj>
  <homeA></homeA>
  <homeB></homeB>
  <homeC></homeC>
  <homeD></homeD>
  <homeE></homeE>
  <ContactContent></ContactContent>
</template>

<script>
import homeTab from '@/components/HomeTab.vue';
import homeA from '@/components/HomeA.vue';
import homeB from '@/components/HomeB.vue';
import homeC from '@/components/HomeC.vue';
import homeD from '@/components/HomeD.vue';
import homeE from '@/components/HomeE.vue';

import homeCont from '@/components/HomeCont.vue';
import homeProj from '@/components/HomeProj.vue';
import ContactContent from '@/components/ContactContent.vue';

export default {
  name: 'HomeView',
  components: {
    homeTab,
    homeA,
    homeB,
    homeC,
    homeD,
    homeE,
    homeCont,
    homeProj,
    ContactContent,
},
};
</script>

<style>
.home {
  padding: 6% 0% 0%;

}
</style>
