<template>
    <div class="homeCont homeProject">
        <div class="tittle">
            <h1 class="tab-tittle" >{{ $t('ff.homeTab') }}</h1>
            <div class="sub-tab">
                <div id="filter"> {{$t('ff.濾鏡設計')}} </div> <div id="l1"> | </div>
                <div id="WebAR"> {{$t('ff.AR網頁')}} </div> <div id="l2"> | </div>
                <div id="LBS"> {{$t('ff.LBS系統')}}</div> <div id="l3"> | </div>
                <div id="ARApp"> {{$t('ff.AR應用')}} </div> <div id="l4"> | </div>
                <div id="AREx"> {{$t('ff.活動設計')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
    },
};
</script>

<style>
.homeCont {
    padding: 5% 0% 0%;
}

.homeCont .tittle .tab-tittle{
    letter-spacing: 0.01em;
    color: #9C91D4 ;
    font-size: 2.3vw;
}

.homeCont .sub-tab {
    padding: 1.5% 0% 2.5%;
    display: flex;
    justify-content: center;
}

.homeCont .sub-tab > div {
    font-size: 1.25vw;
    font-weight: bolder;
    margin-right: 1.5vw;
}

.homeCont .sub-tab > div:last-child {
    margin-right: 0;
}

@media (max-width: 1000px) {
    .homeCont {
        padding: 6% 0% 12%;
    }
    .homeCont .tittle .tab-tittle{
    font-size: 7vw;
    color: rgb(255, 255, 255);
    }
    .homeCont .sub-tab > div {
        margin-right: 5vw;
        font-size: 3vw;
    }
    .homeCont .sub-tab #filter{
        position: absolute;
        margin: 0vw 0vw 0vw -48vw;
    }
    .homeCont .sub-tab #WebAR{
        position: absolute;
        margin: 0vw 0vw 0vw 5vw;
    }
    .homeCont .sub-tab #LBS{
        position: absolute;
        margin: 0vw 0vw 0vw 56vw;
    }
    .homeCont .sub-tab #ARApp{
        position: absolute;
        margin: 9vw 1vw 0vw -39vw;
    }
    .homeCont .sub-tab #AREx{
        position: absolute;
        margin: 9vw 0vw 0vw 42vw;
    }
    .homeCont .sub-tab #l1{
        position: absolute;
        margin: 0vw 0vw 0vw -21vw;
    }
    .homeCont .sub-tab #l2{
        position: absolute;
        margin: 0vw 0vw 0vw 31vw;
    }
    .homeCont .sub-tab #l3{
        display: none;
    }
    .homeCont .sub-tab #l4{
        position: absolute;
        margin: 9vw 5vw 0vw 7vw;
    }
}
</style>
