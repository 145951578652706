<template>
  <div class="project ContactCon">
    <div class="tittle">
      <p class="text1" id="ContactUs">{{ $t('ff.ContactTab') }}</p>
    </div>
    <div>
      <div>
        <form ref="form" @submit.prevent="sendEmail" class="custom-form">
          <div class="Text">
            <div class="form-container">
              <div class="form-group align-left">

                <label class="name" for="name">{{ $t('ff.name') }}
                  <span class="red-star">*</span><br>
                  <input type="text" id="name" name="from_name"  v-model="from_name"
                  class="inputCont" :placeholder="$t('ff.nameP')" required>
                  <div class="checkgender">
                    <label class="gender1" for="gender1">
                      <input type="checkbox" id="gender1" name="isChecked1" checked
                       v-model="checkgender.isChecked1" @click="handleCheckboxClick(1)">
                      <span>{{ $t('ff.gender01') }}</span>
                    </label>
                    <label class="gender2" for="gender2">
                      <input type="checkbox" id="gender2" name="isChecked2"
                       v-model="checkgender.isChecked2" @click="handleCheckboxClick(2)">
                      <span>{{ $t('ff.gender02') }}</span>
                    </label>
                  </div>
                </label>

                <label class="address" for="address">
                  {{ $t('ff.address') }}
                  <span class="red-star">*</span><br>
                  <select id="address" class="inputCont" name="from_area" v-model="from_area"
                      required>
                    <option value="" selected disabled hidden>
                      {{$t('ff.addressP')}}</option>
                    <option>{{ $t('ff.address1') }}</option>
                    <option>{{ $t('ff.address2') }}</option>
                    <option>{{ $t('ff.address3') }}</option>
                    <option>{{ $t('ff.address4') }}</option>
                    <option>{{ $t('ff.address5') }}</option>
                  </select>
                </label>

                <label class="number" for="number">{{ $t('ff.number') }}
                  <span class="red-star">*</span><br>
                  <div class="LRNum">
                    <div class="leftNum">
                      <input type="text"  name="from_call1"  v-model="from_call1" class="inputCont"
                      id="call1" :placeholder="$t('ff.numberC')"
                        required>
                    </div>

                    <div class="rightNum">
                      <input type="text"  name="from_call2"  v-model="from_call2"
                      class="inputCont" id="call2" :placeholder="$t('ff.numberP')"
                        required>
                    </div>
                  </div>

                </label>

                <label class="email" for="email">{{ $t('ff.mail') }}
                  <span class="red-star">*</span><br>
                  <input type="email" id="email"  name="from_email"  v-model="from_email"
                  class="inputCont" :placeholder="$t('ff.mailP')"
                    required>
                </label>
              </div>

              <div class="form-group align-right">
                <label class="company" for="company">
                  {{ $t('ff.company') }}
                  <span class="red-star">*</span><br>
                  <input type="text" id="company"  name="Com"  v-model="Com"
                  class="inputCont" :placeholder="$t('ff.companyP')"
                    required>
                </label>

                <div class="CheckBox">
                  <div class="rightBoxDemand">
                    {{ $t('ff.CheckBox') }}
                    <span class="red-star">*</span>
                  </div>

                  <div class="checkbox-group">
                    <label class="CheckBox01" for="vehicle1">
                      <input type="checkbox" id="vehicle1"  name="CheckBox01"  v-model="CheckBox01">
                      <span>{{ $t('ff.CheckBox01') }}</span>
                    </label>
                    <label class="CheckBox02" for="vehicle2">
                      <input type="checkbox" id="vehicle2"  name="CheckBox02"  v-model="CheckBox02">
                      <span>{{ $t('ff.CheckBox02') }}</span>
                    </label>
                    <label class="CheckBox03" for="vehicle3">
                      <input type="checkbox" id="vehicle3"  name="CheckBox03"  v-model="CheckBox03">
                      <span>{{ $t('ff.CheckBox03') }}</span>
                    </label>
                    <label class="CheckBox04" for="vehicle4">
                      <input type="checkbox" id="vehicle4"  name="CheckBox04"  v-model="CheckBox04">
                      <span>{{ $t('ff.CheckBox04') }}</span>
                    </label>
                    <label class="CheckBox05" for="vehicle5">
                      <input type="checkbox" id="vehicle5"  name="CheckBox05" v-model="CheckBox05">
                      <span>{{ $t('ff.CheckBox05') }}</span>
                    </label>
                    <label class="CheckBox06" for="vehicle6">
                      <input type="checkbox" id="vehicle6" name="CheckBox06" v-model="CheckBox06">
                      <span>{{ $t('ff.CheckBox06') }}</span>
                    </label>
                    <div class="ContText">
                      <textarea type="text" id="reqText" name="from_ContText"
                      v-model="from_ContText" :placeholder="$t('ff.reqP')"></textarea>

                    </div>

                  </div>
                </div>

                <div class="Slider">
                  <div class="tittle">{{ $t('ff.Slider') }}
                    <span class="red-star"> *</span><br>
                  </div>
                  <div class="price-input">
                    <div class="field">
                      <span>Min</span>
                      <input type="number" class="input-min" name="input-min"
                      disabled :placeholder="numK[rangeMin]"
                        v-model="numK[rangeMin]">
                    </div>
                    <div class="separator">-</div>
                    <div class="field">
                      <span>Max</span>
                      <input type="number" class="input-max" name="input-max"
                      disabled :placeholder="numK[rangeMax]"
                        v-model="numK[rangeMax]">
                    </div>
                  </div>
                  <div class="silder">
                    <div class="progress" :style="ProgressStyle"></div>
                  </div>
                  <div class="range-input">
                    <input type="range" class="range-min" min="0" max="11" name="rangeMin"
                    step="1" v-model="rangeMin">
                    <input type="range" class="range-max" min="0" max="11" name="rangeMax"
                    step="1" v-model="rangeMax">
                    <div class="slider-container">
                    </div>
                  </div>
                  <div class="range range-x x" b-class="range.rootclass">
                    <div class="minmaxValue">
                      <p class="p1">50K</p>
                      <p class="p2">1,000K</p>
                    </div>
                  </div>
                </div>
                <button type="submit" id="Send" value="Send" onClick="fbq('track', 'Lead');">
                  {{ $t('ff.Send') }}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      checkgender: {
        isChecked1: true,
        isChecked2: false,
      },
      from_area: '',
      selectedOptions: '',
      sliderValue: 0,
      minValue: 0,
      maxValue: 11,
      value: 0,
      step: 1,
      rangeMin: 1,
      rangeMax: 10,
      steps: [10000, 20000, 20000, 50000, 50000, 50000, 50000, 100000, 100000,
        250000, 250000, 250000, 500000],
      nums: [50000, 60000, 80000, 100000, 150000, 200000, 250000,
        300000, 400000, 500000, 750000, 1000000],
      numK: ['50,000', '60,000', '80,000', '100,000', '150,000', '200,000', '250,000',
        '300,000', '400,000', '500,000', '750,000', '1,000,000+'],
    };
  },
  watch: {
    rangeMax() {
      if (this.rangeMax - this.rangeMin < 2) {
         this.rangeMax = parseInt(this.rangeMin, 10) + 1;
      }
    },
    rangeMin() {
      if (this.rangeMax - this.rangeMin < 2) {
        this.rangeMin = parseInt(this.rangeMax, 10) - 1;
      }
    },
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js';
    script.src = 'https://www.npmjs.com/package/emailjs-com';
    fetch('json/projectTab.json')
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to fetch data.json');
      })
      .then((data) => {
        this.classifications = data;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  },
  computed: {
    currentStep() {
      const currentValue = parseInt(this.sliderValue, 10);
      if (this.sliderValue >= this.maxValue) {
        return `${this.nums[currentValue].toLocaleString()}+ `;
      }
      return `${this.nums[currentValue].toLocaleString()} `;
    },
    ProgressStyle() {
      return {
          left: `${this.rangeMin * 9}%`,
          right: `${100 - this.rangeMax * 9}%`,
        };
    },
  },
  methods: {
    sendEmail(event) {
      event.preventDefault();
      const GenderCheckbox = [];
      const needCheckbox = [];
      document.querySelectorAll('.checkgender input[type="checkbox"]').forEach((checkbox) => {
        if (checkbox.checked) {
          GenderCheckbox.push(checkbox.nextElementSibling.textContent);
          console.log('GenderCheckbox: ', GenderCheckbox);
        }
      });
      document.querySelectorAll('.checkbox-group input[type="checkbox"]').forEach((checkbox) => {
        if (checkbox.checked) {
          needCheckbox.push(checkbox.nextElementSibling.textContent);
          console.log('needCheckbox: ', needCheckbox);
        }
      });
      const serviceID = 'service_80y9jfr';
      const templateID = 'template_1wffzgj';
      const userID = 'lZTXKMbTQRZ3qXnNp';
      const formSele = '.custom-form';
      console.log(formSele);
      const formData = {
        from_name: this.from_name,
        from_area: this.from_area,
        from_call1: this.from_call1,
        from_call2: this.from_call2,
        from_email: this.from_email,
        Com: this.Com,
        from_ContText: this.from_ContText,
        rangeMin: this.numK[this.rangeMin],
        rangeMax: this.numK[this.rangeMax],
        GenderCheckbox: GenderCheckbox.join(','),
        needCheckbox: needCheckbox.join(','),
      };
      emailjs.send(serviceID, templateID, formData, userID)
        .then(() => {
          console.log('Sent');
          alert('Sent');
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
          this.showFormBack();
          alert(JSON.stringify(err));
        });
    },
    handleCheckboxClick(checkboxNumber) {
      if (checkboxNumber === 1) {
        console.log('isChecked2: ', this.checkgender.isChecked2);
        this.checkgender.isChecked2 = false;
      } else if (checkboxNumber === 2) {
        console.log('isChecked1', this.checkgender.isChecked1);
        this.checkgender.isChecked1 = false;
      }
    },
  },
};
</script>

<style>
.name label{
  height: 30px;
  color: #7867c8;
  z-index: 100;
}
#height_slider .e-handle,
#gradient_slider .e-handle {
  height: 20px;
  width: 20px;
  margin-left: -10px;
  top: calc(50% - 10px);
}
  .ContactCon{
    position: relative;
    padding: 0 0 0 0;
  }

  .ContactCon .text1{
    font-size: 2vw;
    font-weight: bold;
    margin-left: 12vw;
    padding: 1vw 6vw 2vw 5vw;
    text-align: left;
  }

  .ContactCon .form {
    font-family: 'Courier New', Courier, monospace;
  }

  .ContactCon .custom-form {
    color: #9786e5;
    max-width: 100%;
    padding: 0% 0% 5%;
    text-align: left;
    flex-wrap: wrap;
  }

  .ContactCon .Text {
    display: flex;
    justify-content: space-between;
  }

  .ContactCon .Text .form-container{
    display: flex;
    width: 100%;
  }

  .ContactCon .align-left {
    padding: 0% 0% 0% 16%;
    margin-bottom: 5vw;
    width: 35%;
    z-index: 1;
  }

  .ContactCon .align-right {
    padding: 0 0% 0% 15%;
    display: flex;
    flex-direction: column;
  }

  .red-star {
    color: red;
  }

  .ContactCon .Text label {
    font-weight: bolder;
    display: block;
    font-size: 1.15vw;
    padding: 1vw;
    color: #85CEC1;
  }

  .ContactCon .Text .inputCont {
    width: 30vw;
    height: 3vw;
    font-size: 1vw;
    padding: 0.5vw 0vw 0.5vw 0.5vw;
    margin: 1vw 0vw 0vw 0vw;
    color: #7867c8;
    background-color: #111110;
    border: 0.1vw solid #7867c8;
    border-radius: 0.5vw;
  }

  .ContactCon .align-left .number .LRNum {
    display: flex;
    align-items: center;
  }

  .ContactCon .align-left .number .LRNum .leftNum .inputCont{
    width: 10vw;
    margin-right: 1vw;
  }

  .ContactCon .align-left .number .LRNum .rightNum .inputCont{
    width: 19vw;
    margin-right: 1vw;
  }

  .ContactCon button {
    position: relative;
    top: 5vw; right: 8vw;
    height: 3vw; width: 13vw;
    padding: 0.8vw 5vw;
    font-size: 1vw; font-weight: bold;
    color: #000000;
    background-color: #85CEC1;
    border: 0.1vw solid #85CEC1;
    border-radius: 0.35vw;
    cursor: pointer;
  }

  .ContactCon button:hover {
    font-weight:bold;
    box-shadow: 0 0 10px #85CEC1;
  }

  .ContactCon #address {
    cursor: pointer;
    -webkit-appearance: none;
    width: 30vw;
    height: 3vw;
    background: url('../assets/data/BackGround/arrow.png')210px center no-repeat #111110;
    background-size: 20px auto;
    background-position: calc(100% - 1vw) center;
    border: 1px solid #7867c8;
    color: #7867c8;
    border-radius: 0.5vw;
  }
  .ContactCon #address option {
      max-height: 100px;
      overflow: hidden;
      white-space: nowrap;
  }

  .ContactCon .align-right .CheckBox {
    color: #8871fc;
    width: 37vw;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }

  .ContactCon .align-right .CheckBox .rightBoxDemand{
    display: block;
    font-size: 1.15vw;
    width: 46vw;
    padding: 1vw;
    font-weight: bold;
    color: #85CEC1;
    z-index: 1;
  }

  .ContactCon .align-right .CheckBox .checkbox-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    z-index: 5;
  }
  .name .checkgender {
    width: 30vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    z-index: 5;
  }

  .ContactCon .align-right .CheckBox .checkbox-group span:hover,
   #vehicle1:hover, #vehicle2:hover, #vehicle3:hover, #vehicle4:hover, #vehicle5:hover,
   #vehicle6:hover, #gender1:hover, #gender2:hover {
    cursor: pointer;
  }

  .ContactCon .align-right .CheckBox .checkbox-group span{
    position: relative;
    font-size: 1vw;
    left: 1vw;
    bottom: 1vw;
  }
  .ContactCon .align-right .CheckBox .checkbox-group label{
    height: 30px;
    color: #7867c8;
    z-index: 100;
  }

  .ContactCon .align-right .CheckBox .ContText{
    position: relative;
    grid-column: span 3;
    height: 5.25vw; width: 30.5vw;
    color: #7867c8;
    border-radius: 0.5vw;
    top: 1.1vw; left: 0.5vw;
  }

  .ContactCon .Text input {
    border: none; outline: none;
    padding: 0.5vw 0vw 2vw 0.5vw;
    width: 100%; height: 100%;
    background-color: #111110;
    border: 0.1vw solid #7867c8;
    border-radius: 0.5vw;
    text-align: left;
    word-wrap: break-word;
    left: 0.5vw;
  }
.ContactCon .name input{
    border: none;
    outline: none;
    padding: 0.5vw 0vw 2vw 0.5vw;
    width: 100%;
    height: 100%;
    background-color: #111110;
    border: 0.1vw solid #7867c8;
    border-radius: 0.5vw;
    text-align: left;
    word-wrap: break-word;
    left: 0.5vw;
  }
  .name .red-star {
    color: red;
  }
  .name span {
    color: #7867c8;
  }
  .ContactCon .CheckBox .ContText input {
    border: 1px solid #7867c8;
    color:#7867c8;
    font-size: 1vw;
  }

  .ContactCon .Text input:valid, .ContactCon #address:valid,
   .ContactCon #email:valid .ContactCon .Text .inputCont:valid {
    color: #7867c8;
  }

  #vehicle1,#vehicle2,#vehicle3, #vehicle4,#vehicle5,#vehicle6 {
    position: relative;
    width: 1vw;
    height: 1vw;
    top: -1vw;
  }
    #gender1, #gender2 {
      position: relative;
      top: 0.05vw;
      width: 1vw;
      height: 1vw;
    }
.slider label {
  font-size: 24px;
  font-weight: 400;
  font-family: Open Sans;
  padding-left: 10px;
  color: black;
}
.slider input[type="range"] {
  width: 420px;
  height: 2px;
  background: black;
  border: none;
  outline: none;
}

  .Slider .tittle {
    font-size: 1.15vw;
    padding: 3vw 0vw 0vw 1vw;
    font-weight: bold;
    color: #85CEC1;
    z-index: 1;
  }

  .Slider .Price .NT{
    font-size: 1vw;
    font-weight: bold;
    color: #7867c8;
    margin: 2vw 0vw 0vw 15vw;
  }

  .Slider .p1,.Slider .p2{
    position: relative;
    color: #BDB6E2;
    font-size: 1vw;
    top: 3vw;
    left: 0vw;
  }

  .Slider .p2{
    left: 28vw;
  }
  .Slider .SliderNum{
    position: relative;
    font-size: 1.5vw;
    color: #BDB6E2;
    left: 18vw;
    top: -1.5vw;
    border-bottom:2px #7B6CC6 solid;
    padding-bottom:2px;
  }
  .ContactCon .Text .custom-slider{
    background-color: #605790;
    height: 1.1px; width: 30vw;
    border: none;
    border-radius: 5px;
    padding: 0%;
    outline: none;
    appearance: none;
  }
  .ContactCon .Text #setting{
    display: flex;
    justify-content: space-between;
    height: auto;
    overflow: hidden;
    margin-top: 16px;
  }
  .ContactCon .Price {
      position: relative;
      top: -2.5vw;
      left: 5vw;
      width: 7vw;
  }
  .ContactCon .align-right .Slider .minmaxValue {
    font-size: 1.1vw;
    color: #7867c8;
    display: flex;
    align-items: center;
    padding: 0 0 0 0vw;
    z-index: 100;
  }
  .slider-container .tick-marks1,.slider-container .tick-marks2,
  .slider-container .tick-marks3, .slider-container .tick-marks4,
  .slider-container .tick-marks5, .slider-container .tick-marks6,
  .slider-container .tick-marks7, .slider-container .tick-marks8,
  .slider-container .tick-marks9, .slider-container .tick-marks10,
  .slider-container .tick-marks11, .slider-container .tick-marks12{
    position: relative;
    height: 9px;
    width: 2.71vw;
    border-left: solid 3px #605790;
    padding: 0;
    box-sizing: border-box;
    display: table-cell;
    top: -9px;
    z-index: -1;
  }

  .Slider .slider-container input::-webkit-slider-thumb {
    appearance: none;
    width: 1.6vw;
    height: 1.6vw;
    background-color: #7867c8;
    border-radius: 50%;
    cursor: pointer;
  }
  .mainSlider{
    position: relative;
    top: -3vw;
    left: 1vw;
  }
  .checkbox-group input[type="checkbox"] ,.name input[type="checkbox"] {
    padding: 0;
    -webkit-appearance: none;
    background-color: #00000000;
    border-radius: 50%;
  }
  .checkbox-group input[type="checkbox"]:checked,
  .name input[type="checkbox"]:checked {
    background-color: #7867c8;
    color: white;
    border: #000000;
  }
  .checkbox-group #reqText {
        position: absolute;
        height: 6vw;
        width: 31vw;
        font-size: 1vw;
        font-family: Arial, Helvetica, sans-serif;
        color: #7867c8 ;
        padding: 0.5vw 0 0 0.5vw;
        border: 1px solid #7867c8;
        border-radius: 0.4vw;
        background: #111110;
  }
  .ContactCon .Text input.input-min, .ContactCon .Text input.input-max{
    padding: 0px 0px 0px 0px;
    width: 6.5vw;
    height: 3vw;
    text-align: center;
    margin: 0 0 0 1vw;
    background: none;
    border: none;
    font-size: 1.2vw;
}
  .ContactCon .silder{
    position: relative;
    top: 2vw;
    height: 0.4vw;
    width: 32vw;
    border-radius: 5px;
    background-color: #605790;
  }
  .ContactCon .silder .progress{
    position: absolute;
  /*  left: 25%;
    right: 25%;*/
    height: 0.4vw;
    border-radius: 5px;
    background-color: #7867c8;
    -moz-appearance: textfield;
  }
  .ContactCon .price-input{
    position: relative;
    display: flex;
    left: 9vw;
  }
  .ContactCon .name label span{
    position: relative;
    left: 1vw;
    font-size: 1.2vw;
  }
  .ContactCon .separator{
    margin: 0vw 2.2vw 0 0vw;
    font-size: 2vw;
  }
  .ContactCon .range-input{
    position: relative;
    top: 2vw;
  }
  .ContactCon .range-input input{
    position: absolute;
    top: -0.25vw;
    left: 0;
    height: 5px;
    width: 32vw;
    padding: 0;
    border:none;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
  }
  .ContactCon .range-input input[type="range"]::-webkit-slider-thumb{
    height: 1.7vw;
    width: 1.7vw;
    border-radius: 50%;
    pointer-events: auto;
    -webkit-appearance: none;
    background: #85CEC1;
  }
  .ContactCon .range-input input[type="range"]::-moz-range-thumb{
    height: 1.7vw;
    width: 1.7vw;
    border-radius: 50%;
    pointer-events: auto;
    -moz-appearance: none;
    background: #85CEC1;
  }
  .ContactCon input.range-min {
    position: absolute;
    z-index: 1;
  }
  .RangeInputMin, .RangeInputMax{
    position: absolute;
    background: #111110;
    color: #7867c8;
    margin: 10px 0 0 27px;
  }
    .ContactCon .Text .inputCont::placeholder {
      color: #7867c8;
    }
    .checkbox-group #reqText::placeholder {
      color: #7867c8;
    }
  .price-input input::placeholder{
    color: #8f81cc;
  }
@media (max-width: 1200px) {
        .ContactCon{
          height: 55vw;
        }
        .ContactCon .tittle{
          font-size: 2vw;
        }
        .ContactCon .text1{
          font-size: 2.5vw;
          font-weight: bold;
          margin: -4vw 0vw 0vw 4vw;
          text-align: left;
        }
        .ContactCon .form {
          font-family: 'Courier New', Courier, monospace;
        }
        .ContactCon .custom-form {
          max-width: 100%;
          padding: 0% 0% 3%;
          text-align: left;
          flex-wrap: wrap;
        }

        .ContactCon .Text {
          display: flex;
          justify-content: space-between;
        }

        .ContactCon .Text .form-container{
          display: flex;
          width: 100%;
        }

        .ContactCon .align-left {
          padding: 0% 0% 0% 9%;
          margin-bottom: 5vw;
          width: 43%;
        }
        .ContactCon .align-right {
          padding: 0 0% 0% 2%;
          margin: 1vw 0vw 0vw 10vw;
        }
        .ContactCon .align-right .CheckBox .rightBoxDemand {
          font-size: 2vw;
        }
        .ContactCon .Text label {
          position: relative;
          font-size: 2vw;
          padding: 1vw;
          z-index: 1;
        }
        .ContactCon button{
          padding: 0%;
          left: -14vw;
        }
      }

@media (max-width: 1000px) {
    .ContactCon{
      width: 100%;
      height: 282vw;
      overflow: hidden;
    }
    .ContactCon .tittle{
      padding: 9vw 6vw 2vw 6vw;
    }
    .ContactCon .text1{
      font-size: 7vw;
      margin-top: 4vw;
      margin-left: 2vw;
      text-align: left;
    }
    .ContactCon .custom-form {
        position: relative;
        max-width: 100%;
        text-align: left;
      }
      .ContactCon .align-left {
        margin-top: 5vw;
        margin-left: 17vw;
        width: 30vw;
        z-index: 0;
      }
      .ContactCon .Text .custom-slider{
        position: relative;
        left: 3vw;
        width: 82vw;
        height: 2px;
      }
      .ContactCon .align-right {
        margin-top: -1vw;
        margin-left: 20vw;
        width: 30vw;
      }
      .ContactCon .Text label {
        font-size: 4.5vw;
        padding: 3vw 0vw 1.5vw 0vw;
        margin-left: -22vw;
      }
      .ContactCon .CheckBox label{
        margin-left: -32vw;
      }
      .ContactCon .Text .inputCont {
        width: 90vw;
        height: 12vw;
        font-size: 4vw;
        border-radius: 2vw;
        padding: 0vw 0vw 0vw 3vw;
      }
      .ContactCon .align-left .number .LRNum .rightNum .inputCont {
        width: 57vw;
      }
      .ContactCon .align-left .number .LRNum .leftNum .inputCont {
        width: 32vw;
      }
      .ContactCon .align-right .company {
          z-index: 100;
          left: -43vw;
          top: 109vw;
      }
      .ContactCon #address {
        width: 90vw;
        height: 13vw;
        padding: 0vw 3vw;
        font-size: 4vw;
        border-radius: 2.5vw;
      }
      .custom-form .company{
        position: relative;
        top: 105vw;
        left: -40vw;
      }
      .ContactCon button {
        position: absolute;
        top: 234vw;
        width: 25vw;
        left: 39vw;
        height: 9vw;
        font-size: 3vw;
        padding: 2vw 3vw;
        border-radius: 2vw;
        cursor: pointer;
        color: #000000;
        border: 0.1vw solid #85CEC1;
        background-color: #85CEC1;
      }
      .custom-form .CheckBox{
        position: absolute;
        top: 135vw;
        left: 4vw;
        width: 39vw;
      }
      .ContactCon .align-right .CheckBox .rightBoxDemand{
        position: relative;
        font-size: 4.5vw;
      }
      .custom-form .CheckBox h1{
        font-size: 4.5vw;
      }
      #vehicle1, #vehicle2, #vehicle3, #vehicle4, #vehicle5, #vehicle6,
      #gender1,
      #gender2 {
        position: relative;
        width: 4vw;
        height: 4vw;
        top: 0vw;
        left: -1vw;
      }
      .CheckBox p {
        font-size: 4.5vw;
      }
      .ContactCon .name label span {
        font-size: 3.5vw;
      }
      .name .checkgender {
        position: relative;
        left: 35vw;
        width: 80vw;
      }
  .checkbox-group input[type="checkbox"]{
    padding: 0;
    -webkit-appearance: none;
    background-color: #00000000;
  }
  .checkbox-group input[type="checkbox"]:checked{
    background-color: #7867c8;
    color: white;
    border: #000000;
  }
      .checkbox-group #reqText {
        height: 18vw;
        width: 90vw;
        left: -14vw;
        font-size: 4vw;
        padding: 3vw 0 0 3vw;
        top: 12.5vw;
        border-radius: 2vw;
      }
      .CheckBox .checkbox-group label{
        padding: 1vw 1vw 1vw 1vw;
        font-size: 4vw;
      }
      .ContactCon .align-right .CheckBox .checkbox-group span {
        position: relative;
        font-size: 4vw;
      }
      .CheckBox .ContText input{
        position: relative;
        top: 13vw;
        height: 23vw;
      }
      .CheckBox .CheckBox01{
        position: relative;
        top: 2vw;
        left: 26vw;
      }
      .CheckBox .CheckBox02{
        position: relative;
        top: 2vw;
        left: 55vw;
      }
      .CheckBox .CheckBox03{
        position: relative;
        top: 11vw;
        left: 6vw;
      }
      .CheckBox .CheckBox04{
        position: relative;
        top: 3vw;
        left: 65vw;
        width: 42vw;
      }
      .CheckBox .CheckBox05{
        position: relative;
        top: 12vw;
        left: 16vw;
      }
      .CheckBox .CheckBox06{
        position: relative;
        top: 12vw;
        left: 45vw;
      }
      .CheckBox .checkbox-group {
         position: absolute;
         top: 8vw;
         margin: 0 0 0 14vw;
      }
      .Slider {
        position: relative;
        top: 173vw;
        left: -65vw;
        width: 90vw;
      }
      .Slider .tittle {
        font-size: 4.5vw;
        padding: 1vw 6vw 0vw 1vw;
      }
      .Slider .SliderNum {
        top: -7.2vw;
        left: 57.2vw;
        font-size: 4vw;
        width: 18vw;
      }
      .Slider .Price .NT {
        position: relative;
        top: -2vw;
        margin: 0vw;
        left: 46vw;
        font-size: 4vw;
      }
      .Slider .line {
        top: 8vw;
        left: 72vw;
        font-size: 4vw;
      }
      .Slider .p1, .Slider .p2{
        top: 12vw;
        left: 2vw;
        font-size: 3vw;
      }
      .Slider .p2{
        left: 75vw;
      }
      .slider-container {
        top: 14vw;
        left: 4vw;
      }
      .Slider .slider-container input::-webkit-slider-thumb {
        width: 5vw;
        height: 5vw;
        margin-top: -0.8vw;
      }
      .TickMark{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .slider-container .tick-marks1,.slider-container .tick-marks2,
      .slider-container .tick-marks3, .slider-container .tick-marks4,
      .slider-container .tick-marks5, .slider-container .tick-marks6,
      .slider-container .tick-marks7, .slider-container .tick-marks8,
      .slider-container .tick-marks9, .slider-container .tick-marks10,
      .slider-container .tick-marks11, .slider-container .tick-marks12{
        width: 7.45vw;
        left: 2.5vw;
        top: -2.5vw;
      }
      .ContactCon .price-input {
        top: 2vw;
        left: 15vw;
        width: 60vw;
      }
      .ContactCon .silder{
        top: 6vw;
        width: 90vw;
      }
      .ContactCon .range-input{
        top: 5.2vw;
      }
      .ContactCon .range-input input{
        width: 90vw;
      }
      .ContactCon .range-input input[type="range"]::-webkit-slider-thumb{
        height: 3.2vw;
        width: 3.2vw;
      }
      .ContactCon .Text input.input-min, .ContactCon .Text input.input-max{
        width: 15vw;
        height: 8vw;
        font-size: 4vw;
        margin: 7px 0 0 16px;
      }
      .RangeInputMin, .RangeInputMax{
        margin: 14px 0 0 27px;
      }
      .ContactCon .separator{
        margin: 3.5vw 5vw 0 3vw;
        font-size: 4vw;
      }
      .ContactCon .silder .progress, .ContactCon .silder{
        height: 1vw;
      }
    }
</style>
