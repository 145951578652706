<template>
    <div class="HomeE">
        <h1 class="Tittle">{{ $t('ff.HomeETittle') }}</h1>
        <p class="Text"> {{ $t('ff.HomeEText') }} </p>

        <button  class='more-more' id="GoAboutPage"
            @click="GoAboutPage">
            {{ $t('ff.HomeEButton01') }}
        </button>

        <button  class='more-more' id="GoCountPage"
            @click="scrollToBottom()">
            {{ $t('ff.HomeEButton02') }}
        </button>
    </div>
</template>

<script>
import router from '@/router';

export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.2/vanilla-tilt.min.js';
        script.async = true;
        document.body.appendChild(script);
    },
    methods: {
        GoAboutPage() {
            router.push('./about');
        },
        GoCountPage() {
            router.push('./Contact');
        },
        scrollToBottom() {
            window.scrollTo({
                top: document.body.scrollHeight - window.innerHeight,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style>
.HomeE {
    position: relative;
    height: 33vw;
    height: 31vw;
}

.HomeE .Tittle{
    font-size: 2vw;
}

.HomeE .Text{
    position: relative;
    margin: 1vw 1vw 5vw;
    font-size: 1.4vw;
    top: 1vw;
    left: 23vw;
    width: 50vw;
}

.HomeE .more-more {
    color: #000000;
    font-size: 1vw;
    font-weight: bold;
    border: none;
    padding: 0.8vw 5vw;
    border-radius: 0.35vw;
    border: 1px solid #85CEC1;
    background-color: #85CEC1;
    /*
    transition: box-shadow 0.3s;
    */
}

.HomeE .more-more:hover {
    box-shadow: 0 0 15px #85CEC1;
    cursor: pointer;
}

.HomeE #GoAboutPage{
    color: #85CEC1;
    font-size: 1vw;
    padding: 0.8vw 5vw;
    border-radius: 0.35vw;
    border: 1px solid #85CEC1;
    background-color: rgba(255, 255, 255, 0);
}

.HomeE #GoAboutPage:hover {
    font-weight:bold;
    box-shadow: 0 0 10px #85CEC1;
    background-color: rgb(27, 5, 65);
    cursor: pointer;
}

.HomeE #GoCountPage{
    margin: 0vw 3vw;
    color: rgb(0, 0, 0);
    background-color: #85CEC1;
}

.HomeE #GoCountPage:hover {
    font-weight:bold;
    box-shadow: 0 0 10px #85CEC1;
}

@media (max-width: 1000px) {
    .HomeE {
        padding: 1vw 0vw 6vw 0vw;
        position: relative;
        height: 62vw;
    }
    .HomeE .Tittle {
        position: relative;
        width: 67vw;
        font-size: 7vw;
        left: 19vw;
    }
    .HomeE .Text {
        font-size: 4vw;
        top: 5vw;
        left: 5vw;
        width: 89vw;
    }
    .HomeE #GoAboutPage{
        margin: 2vw 0vw 0vw 0vw;
        font-size: 3vw;
        padding: 2vw 5vw;
        border-radius: 1.5vw;
        transition: box-shadow 0.3s;
    }
    .HomeE #GoCountPage{
        margin: 2vw 0vw 0vw 2vw;
        font-size: 3vw;
        padding: 2vw 5vw;
        border-radius: 1.5vw;
        transition: box-shadow 0.3s;
    }
}
</style>
