<template>
    <div class="AboutCont">
        <div class="Tittle">
            <h1 >{{ $t('ff.aboutContTittle') }}</h1>
        </div>
        <div class="Cont">
            <p >{{ $t('ff.aboutContText') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
};
</script>

<style>

.Tittle {
    padding: 3% 0% 0%;
    font-size: 1.8vw;
}
.AboutCont .Tittle{
    font-size: 2vw;
    margin: 2vw 0vw;
}
.AboutCont .p{
    line-height: 5vw;
}
.AboutCont .Cont {
    padding: 1vw 15vw;
    font-size: 1.3vw;
}
@media (max-width: 1000px) {
    .AboutCont .Tittle{
        font-size: 3.5vw;
        margin: 2vw 0vw;
    }
    .AboutCont .Cont {
        font-size: 3.5vw;
    }
}
</style>
