<template>
    <div class="about aboutCard">
        <div class="tittle">
            <h1>{{ $t('ff.AboutCardTittle') }}</h1>
            <h3>{{ $t('ff.AboutCardsub') }}</h3>
        </div>
        <!-- ${classification.img} -->
        <div class="justify-content-center aboutCont">
            <div class="row cooperate-card-center ">
                <div class="cooperate-photo-01">

                    <img class="box-photo" id="photo01" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/num1.png" alt=""/>
                    <img class="box-photo" id="photo02" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/num2.png" alt=""/>
                    <img class="box-photo" id="photo03" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/num3.png" alt=""/>

                    <img class="box-photo" id="photoPic01" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/Pic1.png" alt=""/>
                    <img class="box-photo" id="photoPic02" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/Pic3.png" alt=""/>
                    <img class="box-photo" id="photoPic03" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/Pic2.png" alt=""/>

                    <h1 class="box-photo" id="TextT11">{{ $t('ff.AboutTextT11')
                        .replace(/\\n/g, "<br />") }}</h1>
                    <p class="box-photo" id="TextT12">{{ $t('ff.AboutTextT12') }}</p>
                    <h1 class="box-photo" id="TextT21">{{ $t('ff.AboutTextT21') }}</h1>
                    <p class="box-photo" id="TextT22">{{ $t('ff.AboutTextT22') }}</p>
                    <h1 class="box-photo" id="TextT31">{{ $t('ff.AboutTextT31') }}</h1>
                    <p class="box-photo" id="TextT32">{{ $t('ff.AboutTextT32') }}</p>

                </div>

                <div class="cooperate-photo-02">
                    <img class="box-photo" id="photo04" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/num4.png" alt=""/>
                    <img class="box-photo" id="photo05" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/num5.png" alt=""/>
                    <img class="box-photo" id="photo06" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/num6.png" alt=""/>

                    <img class="box-photo" id="photoPic04" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/Pic4.png" alt=""/>
                    <img class="box-photo" id="photoPic05" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/Pic5.png" alt=""/>
                    <img class="box-photo" id="photoPic06" src="https://storage.googleapis.com/download.araizen-ar.com/Araizen/AraizenM/data/cooperation/Pic6.png" alt=""/>

                    <h1 class="box-photo" id="TextT41">{{ $t('ff.AboutTextT41') }}</h1>
                    <p class="box-photo" id="TextT42">{{ $t('ff.AboutTextT42') }}</p>
                    <h1 class="box-photo" id="TextT51">{{ $t('ff.AboutTextT51') }}</h1>
                    <p class="box-photo" id="TextT52">{{ $t('ff.AboutTextT52') }}</p>
                    <h1 class="box-photo" id="TextT61">{{ $t('ff.AboutTextT61') }}</h1>
                    <p class="box-photo" id="TextT62">{{ $t('ff.AboutTextT62') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // about01: 'about01',
            about02: '../data/cooperation/coop002.png',
            about03: '../data/cooperation/coop003.png',
            about04: '../data/cooperation/coop004.png',
            about05: '../data/cooperation/coop005.png',
            about06: '../data/cooperation/coop006.png',
        };
    },
    // methods: {
    //     // getTranslatedImage(imageKey) {
    //     //     return (`../data/cooperation/${this.$t(imageKey)}`);
    //     // },
    // },
};
</script>

<style>
.aboutCard {
    padding: 5% 0% 2.5%;
    color: white;
}

.aboutCard .tittle {
    text-align: left;
    padding: 0% 5%;
    font-size: 1.3vw;
}

.aboutCard .tittle h3 {
    margin: 1% 0%;
    font-size: 1.25vw;
    color: #ffffffc3;
}

.aboutCont {
    padding: 12% 0% 12%;
    display: flex;
    justify-content: center;
}

.cooperate-card-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cooperate-photo-01 {
    display: flex; flex-wrap: wrap;
    justify-content: flex-flex-start;
    align-items: center;
    gap: 15vw; margin-bottom: 10vw;
}

.cooperate-photo-01 .box-photo {
    height: 3vw;
    background-size: cover;
    background-position: center;
    z-index: 100;
}

.cooperate-photo-02 {
    display: flex; flex-wrap: wrap;
    justify-content: flex-flex-start;
    align-items: center;
    gap: 15vw;
}

.cooperate-photo-02 .box-photo {
     height: 3vw;
    background-size: cover;
    background-position: center;
    z-index: 100;
}
.arrow-container {
    position: relative;
    z-index: 88;
}

.arrow {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 100px; height: 20px;
    border-bottom: 1px solid #000000;
}
#photo01{
    position: absolute;
    top: 13vw;
    left: 11vw;
}
#photo02{
    position: absolute;
    top: 13vw;
    left: 40vw;
}
#photo03{
    position: absolute;
    top: 13vw;
    left: 70vw;
}
#photo04{
    position: absolute;
    top: 30vw;
    left: 11vw;
}
#photo05{
    position: absolute;
    top: 30vw;
    left: 40vw;
}
#photo06{
    position: absolute;
    top: 30vw;
    left: 70vw;
}
#photoPic01{
    position: absolute;
    top: 16vw;
    height: 6vw;
    left: 11vw;
}
#photoPic02{
    position: absolute;
    top: 16vw;
    height: 6vw;
    left: 40vw;
}
#photoPic03{
    position: absolute;
    top: 16vw;
    height: 6vw;
    left: 70vw;
}
#photoPic04{
    position: absolute;
    top: 33vw;
    height: 6vw;
    left: 11vw;
}
#photoPic05{
    position: absolute;
    top: 33vw;
    height: 6vw;
    left: 40vw;
}
#photoPic06{
    position: absolute;
    top: 33vw;
    height: 6vw;
    left: 70vw;
}
#TextT11, #TextT21, #TextT31, #TextT41, #TextT51, #TextT61{
    position: absolute;
    font-size: 2vw;
    color: #85CEC1;
    font-weight:bold;
    text-align: left;
}
#TextT11{
    top: 13vw;
    height: 6vw;
    left: 18vw;
}
#TextT12{
    position: absolute;
    top: 17vw;
    height: 6vw;
    width: 14vw;
    left: 18vw;
    font-size: 1.2vw;
    text-align: left;
}
#TextT21{
    top: 13vw;
    height: 6vw;
    left: 48vw;
}
#TextT22{
    position: absolute;
    top: 17vw;
    height: 6vw;
    width: 14vw;
    left: 48vw;
    font-size: 1.2vw;
    text-align: left;
}
#TextT31{
    top: 13vw;
    height: 6vw;
    left: 78vw;
}
#TextT32{
    position: absolute;
    top: 17vw;
    height: 6vw;
    width: 14vw;
    left: 78vw;
    font-size: 1.2vw;
    text-align: left;
}
#TextT41{
    top: 30vw;
    height: 6vw;
    left: 18vw;
}
#TextT42{
    position: absolute;
    top: 34vw;
    height: 6vw;
    width: 14vw;
    left: 18vw;
    font-size: 1.2vw;
    text-align: left;
}
#TextT51{
    top: 30vw;
    height: 6vw;
    left: 48vw;
}
#TextT52{
    position: absolute;
    top: 34vw;
    height: 6vw;
    width: 14vw;
    left: 48vw;
    font-size: 1.2vw;
    text-align: left;
}
#TextT61{
    top: 30vw;
    height: 6vw;
    left: 78vw;
}
#TextT62{
    position: absolute;
    top: 34vw;
    height: 6vw;
    width: 15vw;
    left: 78vw;
    font-size: 1.2vw;
    text-align: left;
}
@media (max-width: 1000px) {
    .aboutCard .tittle h3 {
        margin: -1% 6%;
        font-size: 3.5vw;
    }
    .about.aboutCard{
        position: sticky;
        height: 194vw;
    }
    .cooperate-card-center {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }
    .cooperate-photo-01 {
        display: flex;
        flex-direction: column;
        justify-content: flex-flex-start;
        align-items: center;
        gap: 5vw;
    }
    .cooperate-photo-01 .box-photo {
        height: 4vw;
        background-size: cover;
        background-position: center;
        z-index: 100;
    }
    .cooperate-photo-02 {
        display: flex;
        flex-direction: column;
        justify-content: flex-flex-start;
        align-items: center;
        gap: 5vw;
    }
    .cooperate-photo-02 .box-photo {
        height: 4vw;
        background-size: cover;
        background-position: center;
        z-index: 100;
    }
    #photo01{
        position: absolute;
        top: 34vw;
        left: 11vw;
        height: 7vw;
    }
    #photo02{
        position: absolute;
        top: 62vw;
        left: 11vw;
        height: 7vw;
    }
    #photo03{
        position: absolute;
        top: 90vw;
        left: 11vw;
        height: 7vw;
    }
    #photo04{
        position: absolute;
        top: 118vw;
        left: 11vw;
        height: 7vw;
    }
    #photo05{
        position: absolute;
        top: 146vw;
        left: 11vw;
        height: 7vw;
    }
    #photo06{
        position: absolute;
        top: 174vw;
        left: 11vw;
        height: 7vw;
    }
    #photoPic01{
        position: absolute;
        top: 31vw;
        height: 12vw;
        left: 26vw;
    }
    #photoPic02{
        position: absolute;
        top: 59vw;
        height: 12vw;
        left: 26vw;
    }
    #photoPic03{
        position: absolute;
        top: 87vw;
        height: 12vw;
        left: 26vw;
    }
    #photoPic04{
        position: absolute;
        top: 115vw;
        height: 12vw;
        left: 26vw;
    }
    #photoPic05{
        position: absolute;
        top: 144vw;
        height: 12vw;
        left: 26vw;
    }
    #photoPic06{
        position: absolute;
        top: 172vw;
        height: 12vw;
        left: 26vw;
    }
    #TextT12, #TextT22,#TextT32,#TextT42,#TextT52,#TextT62 {
        position: absolute;
        width: 50vw;
        font-size: 3.5vw;
        text-align: left;
        left: 42vw;
        height: 6vw;
    }
    #TextT11{
        font-size: 6vw;
        color: #85CEC1;
        font-weight: bold;
        position: absolute;
        top: 30vw;
        height: 6vw;
        left: 41vw;
        text-align: left;
    }
    #TextT12{
        top: 38vw;
    }
    #TextT21{
        font-size: 6vw;
        color: #85CEC1;
        font-weight: bold;
        position: absolute;
        top: 57vw;
        height: 6vw;
        left: 41vw;
        text-align: left;
    }
    #TextT22{
        top: 65vw;
    }
    #TextT31{
        font-size: 6vw;
        color: #85CEC1;
        font-weight: bold;
        position: absolute;
        top: 84vw;
        height: 3vw;
        left: 41vw;
        text-align: left;
    }
    #TextT32{
        top: 92vw;
    }
    #TextT41{
        font-size: 6vw;
        color: #85CEC1;
        font-weight: bold;
        position: absolute;
        top: 115vw;
        height: 6vw;
        left: 41vw;
        text-align: left;
    }
    #TextT42{
        top: 123vw;
    }
    #TextT51{
        font-size: 6vw;
        color: #85CEC1;
        position: absolute;
        top: 142vw;
        height: 6vw;
        left: 41vw;
        text-align: left;
    }
    #TextT52{
        top: 150vw;
    }
    #TextT61{
        font-size: 6vw;
        color: #85CEC1;
        position: absolute;
        top: 170vw;
        height: 6vw;
        left: 42vw;
        text-align: left;
    }
    #TextT62{
        top: 178vw;
    }
}
</style>
