<template>
    <div class="homeProj">
        <div class="justify-content-center">
            <div class="row card-center ">
                <div class="empty-photo"
                    v-for="(classification,index) in filteredClassifications"
                    :key="classification.id"
                    v-show=" showAll || index < showNum ">
                            <div class="ButtonBackGround" v-if="isMobile" >
                                <button :class="{ 'fa fa-plus': isPlue, 'fa fa-minus': !isPlue }"
                                    @click="ClickPic(classification, currentLanguage)"     >
                                </button>
                                <div>
                                    <div class="Phonedescription" v-if="currentLanguage === 'ch'">
                                        {{ classification.ChName }}<br>
                                        <div class="horizontalLine"></div>
                                        <br><span class="sub" v-if="currentLanguage === 'ch'">
                                            {{ classification.ChContent }}</span><br>
                                     </div>

                                    <div class="Phonedescription" v-if="currentLanguage === 'en'">
                                        {{ classification.EnName }}<br>
                                        <div class="horizontalLine"></div>
                                        <br><span class="sub" v-if="currentLanguage === 'en'">
                                        {{ classification.EnContent }}</span><br>
                                    </div>
                            </div></div>
                    <div class="prj-photo">
                        <div class="prj-photo-img"
                            @mouseenter="playVideo"
                            @mouseleave="pauseVideo"
                            @focus="playVideo"
                            @blur="pauseVideo">
                            <!-- <div class="overlay" v-if="showOverlay"/> -->
                            <video class="Video" autoplay muted loop playsinline
                                ref="video" :src="classification.videoSrc"
                                controlslist="nodownload" />
                        </div>
                    </div>

                    <div> <!--v-if="classification.ShowText"-->
                        <div class="description" v-if="currentLanguage === 'ch'">
                            {{ classification.ChName }}
                            <br>
                            <div class="horizontalLine"></div>
                            <br>
                            <span class="sub" v-if="currentLanguage === 'ch'">
                                {{ classification.ChContent }}</span><br>
                         </div>

                        <div class="description" v-if="currentLanguage === 'en'">
                            {{ classification.EnName }}
                            <br>
                            <div class="horizontalLine"></div>
                            <br><span class="sub" v-if="currentLanguage === 'en'">
                            {{ classification.EnContent }}</span><br>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <button  class='more-more'
            @click="scrollToBottom()">
            {{ $t('ff.HomeButton') }}
        </button>
    </div>
</template>

<script>
import router from '@/router';

export default {
    data() {
        return {
            isPlue: true,
            classifications: [],
            showNum: 6,
            btn: '深入了解',
            currentLanguage: this.$i18n.locale,
            isMobile: false,
        };
    },
    mounted() {
        this.checkIfMobile();
        fetch('./json/projectCont.json')
            .then((response) => {
                if (response.ok) {
                return response.json();
                }
                throw new Error('Failed to fetch data.json');
            })
            .then((data) => {
                this.classifications = data;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },
    computed: {
        filteredClassifications() {
            return this.classifications;
        },
    },
    methods: {
        redirectToAnotherPage() {
            router.push('./project');
        },
        ClickPic(classification) {
            console.log('1');
            console.log(classification);
        },
        checkIfMobile() {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        scrollToBottom() {
            window.scrollTo({
                top: document.body.scrollHeight - window.innerHeight,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style>
.homeProj{
    padding: 0% 3.5% 0% 5%;
}
.homeProj .justify-content-center{
    padding: 0% 0% 0.8%;
}
.empty-photo .ButtonBackGround{
    position: absolute;
    top: 1.9vw;
    left: 3.9vw;
    width: 91vw;
    height: 68vw;
    background: rgba(255, 255, 255, 0);
}

.empty-photo .ButtonBackGround:hover{
    background-color: #18101eeb;
    animation: BGShow 10s;
}
    /*@keyframes BGShow {
      0%{
        opacity: 100;
        transform: translate3d(100vw, 0, 0);
      }
    }*/
.ButtonBackGround .Phonedescription{
    display: none;
}
.ButtonBackGround:hover .Phonedescription{
    display: block;
    line-height: 5vw;
}

.empty-photo button:hover{
    background-color: rgba(255, 235, 205, 0);
}

.horizontalLine {
  border: none;
  border-top: 2.5px solid #85CEC1;
  margin: 12px auto;
  margin-bottom: 0;
  width: 75%;
}
.homeProj .more-more{
    color: rgb(0, 0, 0);
    font-size: 1.15vw;
    padding: 0.8vw 5vw;
    font-weight:bold;
    border-radius: 0.35vw;
    border: 1px solid #85CEC1;
    background-color: #85CEC1;
}

.homeProj .more-more:hover {
    font-weight:bold;
    box-shadow: 0 0 10px #85CEC1;
    cursor: pointer;
}
@media (max-width: 1000px) {
    .homeProj{
        padding: 0% 0% 0% 0%;
    }
}
</style>
