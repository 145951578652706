<template>
    <div class="project projectContent">
        <div :class="['justify-content-center',{'small-screen':isSmallScreen}]">
            <div class="row card-center ">
                <div class="empty-photo"
                    v-for="(classification,index) in filteredClassifications"
                        :key="classification.id"
                        v-show=" showAll || index < showNum ">

                        <div class="ButtonBackGround" v-if="isMobile" >
                            <button :class="{ 'fa fa-plus': isPlue, 'fa fa-minus': !isPlue }"
                                @click="ClickPic(classification, currentLanguage)"     >
                            </button>
                            <div>
                                <div class="Phonedescription" v-if="currentLanguage === 'ch'">
                                    {{ classification.ChName }}<br>
                                    <div class="horizontalLine"></div>
                                    <br><span class="sub" v-if="currentLanguage === 'ch'">
                                        {{ classification.ChContent }}</span><br>
                                 </div>

                                <div class="Phonedescription" v-if="currentLanguage === 'en'">
                                    {{ classification.EnName }}<br>
                                    <div class="horizontalLine"></div>
                                    <br><span class="sub" v-if="currentLanguage === 'en'">
                                    {{ classification.EnContent }}</span><br>
                                </div>
                        </div></div>

                        <div class="prj-photo" @click="redirectToAnotherPage(classification.Link)"
                         @keydown.enter="redirectToAnotherPage">
                            <div class="prj-photo-img"
                                @mouseenter="playVideo"
                                @mouseleave="pauseVideo"
                                @focus="playVideo"
                                @blur="pauseVideo">
                                <video class="Video" autoplay muted loop playsinline
                                    ref="video" :src="classification.videoSrc"/>
                            </div>
                        </div>

                        <div> <!--v-if="classification.ShowText"-->
                            <div class="description" v-if="currentLanguage === 'ch'">
                                {{ classification.ChName }}
                                <br>
                                <div class="horizontalLine"></div>
                                <br>
                                <span class="sub" v-if="currentLanguage === 'ch'">
                                    {{ classification.ChContent }}</span><br>
                             </div>

                            <div class="description" v-if="currentLanguage === 'en'">
                                {{ classification.EnName }}
                                <br>
                                <div class="horizontalLine"></div>
                                <br><span class="sub" v-if="currentLanguage === 'en'">
                                {{ classification.EnContent }}</span><br>
                            </div>

                        </div>
                </div>
            </div>
        </div>

        <button class='more-more' id="abc"
        v-if="!showAll && filteredClassifications.length > showNum"
            @click="showAll = true">
            {{ $t('ff.ProjectButton') }}
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isPlue: true,
            classifications: [],
            showNum: 9,
            showAll: false,
            currentLanguage: this.$i18n.locale,
            isMobile: false,
        };
    },
    mounted() {
        this.checkIfMobile();
        fetch('./json/projectCont.json')
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to fetch data.json');
            })
            .then((data) => {
                this.classifications = data;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },
    computed: {
        filteredClassifications() {
            const projectState = this.$store.state.projectState[0];
            if (projectState === '全部' || projectState === 'ALL') {
                return this.classifications;
            }
            if (projectState) {
                const filtered = this.classifications.filter(
                    (classification) => classification.tab === projectState,
                );
                return filtered;
            }
            return [];
        },
    },
    methods: {
        ClickPic(classification, currentLanguage) {
            console.log('1', currentLanguage);
            if (this.images && this.images[classification]) {
                console.log('有圖片');
                this.images[classification].isPlue = !this.images[classification].isPlue;
                this.images[classification].ShowText = !this.images[classification].ShowText;
                console.log('ShowText');
            }
        },
        checkIfMobile() {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        redirectToAnotherPage(Link) {
            console.log(Link);
            // window.open(Link);
        },
    },
};
</script>

<style>
.projectContent {
    padding: 2% 2% 2% 4%;
}

.card-center {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.prj-photo-img .Video {
    height: 100%;
    width: 100%;
}

.empty-photo {
  flex-basis: calc(100% / 3 - 2vw);
  height: 22vw;
  margin-right: 0.7vw;
  margin-left: 0.7vw; margin-bottom: 1vw;
  background-size: cover;
  background-position: center;
  position: relative;
}

.empty-photo .description {
    display: none;
    position: absolute;
    top: 50%; left: 50%;
    color: #85CEC1;
    transform: translate(-50%, -50%);
    font-size: 1.3vw; font-weight: bold;
    width: 18vw;
    transition: max-height 0.3s ease;
}
.empty-photo .Phonedescription {
    position: absolute;
    top: 35vw;
    left: 46vw;
    color: #85CEC1;
    transform: translate(-50%, -50%);
    font-size: 5vw; font-weight: bold;
    width: 75vw;
    transition: max-height 0.3s ease;
}
.empty-photo .sub {
    color: aliceblue;;
    font-size: 1vw; font-weight: normal;
}

.empty-photo:hover .description {
    display: block;
    pointer-events: none;
}

.empty-photo .prj-photo {
    height: 100%;
    overflow: hidden;
}

.empty-photo .prj-photo .Video{
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    transform: scale(1);
}

.empty-photo .Video:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.prj-photo .Video:hover {
    transform: scale(1);
    filter: brightness(0.25);
    transition: 0.3s ease;
    cursor: pointer;
}
.prj-photo .Video:active {
    filter: brightness(0);
}

.projectContent .more-more {
    color: #85CEC1;
    font-size: 1vw;
    margin: 1vw 0 4vw 0;
    padding: 0.8vw 5vw;
    border-radius: 0.35vw;
    border: 1px solid #85CEC1;
    background-color: rgba(255, 255, 255, 0);
}

.projectContent .more-more:hover {
    font-weight:bold;
    box-shadow: 0 0 10px #85CEC1;
    background-color: rgb(27, 5, 65);
    cursor: pointer;
}
/*
@media (max-width: 900px) {
    .empty-photo {
        flex: 0 0 calc(100%/2 - 1.5vw);
        width: 66vw;
        height: 38vw;
        margin: 0.5vw;
        background-size: cover;
        background-position: center;
        position: relative;
    }
    .empty-photo .description {
        top: 54%;
        font-size: 3vw;
    }
}*/
@media (max-width:1080px) {
    .projectContent {
        padding: 2% 2% 0% 0%;
    }
    .empty-photo:hover .description {
        display: none;
        pointer-events: none;
    }
    .empty-photo .description {
        display: none;
        position: absolute;
        top: 38vw;
        left: 50vw;
        color: aliceblue;
        transform: translate(-50%, -50%);
        font-size: 6vw;
        width: 71vw;
    }
    .empty-photo {
        align-items: center;
        height: 100%;
    }
    .empty-photo .prj-photo {
        width: 90vw;
        margin: 2vw 4vw;
        overflow: hidden;
    }
    .empty-photo .prj-photo .Video {
        height: 100%;
        background-size: cover;
        background-position: center;
        position: relative;
        z-index: -1;
        transform: scale(1);
        align-items: center;
        --brightness: 100%;
        filter: brightness(var(--brightness));
    }
    .empty-photo .sub {
        font-size: 4.5vw;
        font-weight: normal;
    }
    .projectContent .more-more {
        background-color: #85CEC1;
        font-size: 3vw;
        color: rgb(0, 0, 0);
        border: none;
        padding: 3vw 9vw;
        margin: 6vw;
        border-radius: 2vw;
        transition: box-shadow 0.3s;
    }
    .empty-photo .Video:hover::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }/*
    .prj-photo .Video:hover {
        transform: scale(1);
        filter: brightness(1);
        transition: 0.3s ease;
        cursor: pointer;
    }*/
    .homeProj .more-more{
        font-size: 3vw;
        padding: 2.5vw 8vw;
        border-radius: 1.5vw;
        margin: 2vw 0vw 0vw 0vw;
    }
    .homeProj .icons{
        position: absolute;
        top: 63vw;
        left: 88vw;
    }
    .fa-plus,.homeProj .fa-minus{
        position: absolute;
        top: 56.8vw;
        left: 79.5vw;
        z-index: 1;
        width: 11vw;
        height: 11vw;
        font-size: 4.5vw;
        padding: 2.5vw 0vw 0vw 2.5vw;
        border: 0vw;
        color: #BDB6E2;
        background: #362B6F;
        border-radius: 13vw 0vw 0vw 0vw;
    }/*
    .prj-photo-img .Video:hover {
        transform: scale(1.12);
        filter: brightness(0.25);
        transition: 0.3s ease;
        cursor: pointer;
    }*/
}
</style>
