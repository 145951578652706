<template>
    <div class="HomeD">

        <div class="Text">
        <h1 class="Text03">{{ $t('ff.HomeDText03') }}</h1>
        <h1 class="Tittle">{{ $t('ff.HomeDTittle') }}</h1>
            <div id="Photo2">
                <img width="1600" height="880"
                        src="../assets/data/Home/D/02.png" alt="Photo 2">
                    <h1 class="Title01">{{ $t('ff.HomeDTittle01') }}</h1>
                <p class="Text01">{{ $t('ff.HomeDText01') }}</p>
            </div>

            <div id="Photo3">
                    <img width="1600" height="880"
                        src="../assets/data/Home/D/03.png" alt="Photo 3">
                <h1 class="Title02">{{ $t('ff.HomeDTittle02') }}</h1>
                <p class="Text02">{{ $t('ff.HomeDText02') }}</p>
            </div>
        <img id="Photo1" width="3750" height="3750"
                        src="../assets/data/Home/D/01.png" alt="Photo 1">
        </div>

                <!-- <p class="Text03"> {{ $t('ff.HomeDText03') }} </p>
                <h1 class="Tittle">{{ $t('ff.HomeDTittle') }}</h1>
                <div id="Photo2">
                    <img src="../assets/data/Home/D/02.png" alt="Photo 2">
                    <h1 class="Title01">{{ $t('ff.HomeDTittle01') }}</h1>
                    <p class="Text01">{{ $t('ff.HomeDText01') }}</p>
                </div>

                <div id="Photo3">
                    <img src="../assets/data/Home/D/03.png" alt="Photo 3">
                    <h1 class="Title02">{{ $t('ff.HomeDTittle02') }}</h1>
                    <p class="Text02">{{ $t('ff.HomeDText02') }}</p>
                </div>

            <div>
                <img id="Photo1" src="../assets/data/Home/D/01.png" alt="Photo 1">
            </div> -->
    </div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.2/vanilla-tilt.min.js';
        script.async = true;
        document.body.appendChild(script);
    },
};
</script>

<style>
.HomeD {
    /*
    padding: 5vw 0vw 0vw 0vw;
    position: relative;
    height: 44vw;
    */
    padding: 6vw 0vw 0vw 0vw;
    display: flex;
    justify-content: space-between;
}
.HomeD .Text{
    height: 50vw;
}
.HomeD .rightGroup {
    flex-basis: 50%;
    position: relative;
    bottom: 2vw;
    right: 5vw;
}
.HomeD .Tittle{
    position: relative;
    text-align: left;
    width: 85vw;
    font-size: 2.3vw;
    left: 22vw;
    top: 7vw;
}

.HomeD #Photo1{
        position: relative;
        width: 25vw;
        height: 25vw;
        top: -20vw;
        left: 30vw;
}

.HomeD .PhtCont {
    margin-left: 15vw;
    margin-top: -6.5vw;
}

.HomeD .PhtCont h1 {
    font-size: 1.5vw;
    font-weight: bold;
    padding-bottom: 5%;
    color: #7B6CC6;
}

.HomeD .PhtCont p {
    font-size: 1vw;
    color: rgb(255, 255, 255);
    width: 18vw;
}

.HomeD .PhtCont h1, .PhtCont p{
    text-align: left;
    width: 220%;
}

.HomeD .Text03{
    left: 19.5vw;
    top: 9vw;
    font-size: 1.1vw;
    position: relative;
    margin: 0vw 0vw 0vw 2vw;
    color: #85CEC1;
    text-align: left;
}

.HomeD #Photo2, .HomeD #Photo3{
    width: 50%;
}

.HomeD #Photo2 img, .HomeD #Photo3 img{
    position: relative;
    left: 6vw;
    top: 9vw;
    width: 13vw;
    height: 8vw;
}

.HomeD .Title01,.HomeD .Title02 {
    position: relative;
    width: 24vw;
    left: 35vw;
    top: 2vw;
    color: #7B6CC6;
    font-size: 1.5vw;
    text-align: left;
}

.HomeD .Text .Text01,.HomeD .Text .Text02{
    position: relative;
    width: 21vw;
    left: 35vw;
    top: 2vw;
    text-align: left;
    font-size: 1.1vw;
}

@media (max-width: 1000px) {
    .HomeD {
        padding: 7vw 0vw 0vw;
        height: 180vw;
        position: relative;
    }
    .HomeD .Tittle {
        left: 15vw;
        font-size: 6vw;
    }
    .HomeD #Photo1 {
        position: relative;
        width: 50vw;
        height: 50vw;
        top: -87vw;
        left: 7vw;
    }
    .HomeD .Text03 {
        font-size: 3.5vw;
        left: 14vw;
        color: cadetblue;
    }
    .HomeD .Text .Title01, .HomeD .Text .Title02 {
        width: 50vw;
        left: 23vw;
        top: 17vw;
        font-size: 6vw;
    }
    .HomeD .Text .Text01, .HomeD .Text .Text02 {
        width: 50vw;
        left: 23vw;
        top: 19vw;
        font-size: 4.1vw;
    }
    .HomeD #Photo2 img, .HomeD #Photo3 img{
        position: relative;
        top: 40vw;
        left: -7vw;
        width: 26vw;
        height: 16vw;
    }
    .HomeD #Photo2, .HomeD #Photo3{
        position: relative;
        width: 20vw;
        top: 35vw;
        left: 19vw;
    }
}
</style>
