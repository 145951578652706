<template>
    <div class="project ContactTab">
        <div class="background"/>
        <div class="headtitle">
            <p class="text1">{{ $t ('ff.title') }}<br>{{$t ('ff.title2')}}</p>
            <div class="text2">
                <p>{{$t ('ff.A') }}</p>
                <p>M : service@araizen.com</p>
                <p>{{$t ('ff.T') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import emailjs from 'emailjs-com';

export default {
    data() {
        return {
        };
    },
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js';
        script.src = 'https://www.npmjs.com/package/emailjs-com';
        fetch('json/projectTab.json')
            .then((response) => {
                if (response.ok) {
                return response.json();
                }
                throw new Error('Failed to fetch data.json');
            })
            .then((data) => {
                this.classifications = data;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },
    methods: {
        handleItemClick(item) {
            console.log('Clicked:', item);
        },
        toggleTab(id) {
            // 清空 projectState
            this.$store.commit('SET_PROJECTS_STATE', []);
            // 查找对应的 classification 并将其 name 写入 projectState
            const classification = this.classifications.find((c) => c.id === id);
            if (classification) {
                this.$store.commit('SET_CLASSIFICATION_STATE', classification);
                this.$store.commit('SET_PROJECTS_STATE', [classification.name]);
            }
            // console.log(this.$store.state.[0]);
        },
        sendEmail(event) {
            event.preventDefault();

            const serviceID = 'service_80y9jfr';
            const templateID = 'template_1wffzgj';

            emailjs.sendForm(serviceID, templateID, this.$refs.form, 'lZTXKMbTQRZ3qXnNp')
                .then(() => {
                    console.log('Sent');
                })
                .catch((err) => {
                    console.log(JSON.stringify(err));
                });
            },
    },
};
</script>

<style>
.ContactTab{
    padding: 0%;
   /* top: 9vw;
    position: relative;*/
}
.ContactTab .headtitle .text1{
    position: absolute;
    left: 18vw;
    top: 65vw;
    line-height: 2.5vw;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8vw;
    color: #FFFFFF;
}
.ContactTab .headtitle .text2{
    position: absolute;
    left: 18vw;
    top: 72vw;
    font-size: 1.1vw;
    line-height: 2vw;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
}
.ContactTab .tittle .text1{
    position: absolute;
    margin-top: 0vw;
    padding: 1% 20%;
    font-size: 2vw;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
}
  .ContactTab .background {
    position: relative;
    padding: 15vw;
    filter: brightness(0.8);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("../assets/data/BackGround/ContactBackground.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -2;
  }
  @media (max-width: 1000px) {
    .ContactTab{
        position: relative;
        padding: 5vw 0vw 0vw 0vw;
    }
    .ContactTab .headtitle{
        display: none;
    }
    .ContactTab .headtitle .text2{
        position: absolute;
        left: 18vw;
        top: 41vw;
        font-size: 1.1vw;
        line-height: 2vw;
        font-weight: bold;
        text-align: left;
        color: brown;
    }
    .ContactTab .headtitle .text1{
        position: absolute;
        margin-top: 0vw;
        padding: 1% 20%;
        font-size: 2vw;
        color: brown;
        font-weight: bold;
        text-align: left;
    }
  }
</style>
