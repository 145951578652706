<template>
    <footer class="footer">
        <div class="navbar">
            <div class="sub-tab">
                <div> <a id="Company"> 2023 瑞盛數位科技股份有限公司</a> </div> <div id="l"> | </div>
                <div> <a id="Araizen"> © Araizen Co. , Ltd.</a> </div> <div id="l"> | </div>
                <div> <a id="Gmail" :href="'mailto:' + emailAddress" >
                            service@araizen.com </a> </div> <div id="l"> | </div>
                <div> <a id="Phone" :href="'tel:' + phoneNumber">
                             +886-2-77091286 </a> </div> <div id="l"> | </div>
                <div> <a id="Address"> {{$t('ff.Address')}} </a> </div> <div id="l"></div>
                <div class="link">
                    <a href="https://www.facebook.com/AraizenAR/" target="_blank">
                        <font-awesome-icon :icon="['fab', 'facebook-f']" />
                        {{ fb }}
                    </a>
                    <a href="https://instagram.com/araizen_official?igshid=YmMyMTA2M2Y=" target="_blank">
                        <font-awesome-icon :icon="['fab', 'instagram']" />
                        {{ fb }}
                    </a>
                    <a href="https://www.linkedin.com/company/araizen/" target="_blank">
                        <font-awesome-icon :icon="['fab', 'linkedin']" />
                        {{ fb }}
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(faFacebookF, faInstagram, faLinkedin);

export default {
    data() {
        return {
            emailAddress: 'service@araizen.com',
            phoneNumber: '886277091286',
        };
    },
    components: {
        FontAwesomeIcon,
    },
};
</script>

<style>
.footer {
    background-color: #362B6F;
    height: 3.5vw; width: 100%;
    color: rgba(212, 218, 218, 1);
    font-weight: 40vw;
    position: relative;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .sub-tab {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .sub-tab > div {
    font-size: 1vw;
    font-weight: bold;
    margin-right: 1.75vw;
}

.footer .sub-tab > div:last-child {
    margin-right: 0;
}

.footer a {
    text-decoration: none;
    font-size: 0.9vw;
    color: white;
}
.footer .link a{
    font-size: 1.3vw;
    margin-right: 1.5vw;
}
  @media (max-width: 1000px) {
    .footer {
    background-color: #362B6F;
    height: 200px; width: 100%;
    color: rgba(212, 218, 218, 1);
    font-weight: 50px;
    position: relative;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .footer a{
        text-decoration: none;
        font-size: 3.5vw;
        text-align: left;
    }
    .footer link{
        text-decoration: none;
        font-size: 2.5vw;
    }
    .footer #Company{
        position: absolute;
        bottom: 165px;
        left: 20px;
    }
    .footer #Araizen{
        position:Absolute ;
        bottom: 139px;
        left: 20px;
    }
    .footer #Gmail{
        position:Absolute;
        bottom: 114px;
        left: 20px;
    }
    .footer #Phone{
        position:Absolute;
        bottom: 89px;
        left: 20px;
    }
    .footer #Address {
        position: Absolute;
        top: 114px;
        left: 20px;
        width: 88vw;
    }
    .footer .link{
        position:Absolute;
        bottom: 25px;
        left: 20px;
    }
    .footer #l{
        display: none;
    }
    .footer .link a{
        font-size: 22px;
        margin-right: 10px;
    }
}
</style>
