<template>
    <div class="contact">
        <ContactContent class="ContContent"></ContactContent>
        <ContactTab></ContactTab>
    </div>

</template>

<script>
import ContactTab from '@/components/ContactTab.vue';
import ContactContent from '@/components/ContactContent.vue';

export default {
  name: 'ContactView',
  components: {
    ContactTab,
    ContactContent,
  },
};
</script>

<style scoped>
.ContContent {
  padding: 8vw 0 0 0;
}
</style>
