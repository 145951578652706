<template>
    <div class="project">
        <projectTab></projectTab>
        <projectContent></projectContent>
    </div>
</template>

<script>
import projectTab from '@/components/ProjectTab.vue';
import projectContent from '@/components/ProjectContent.vue';

export default {
  name: 'ProjectView',
  components: {
    projectTab,
    projectContent,
  },
};
</script>
