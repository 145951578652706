<template>
    <div class="about aboutTab">

        <div class="tab-photo">
            <div class="tab-Backg"
                @mouseenter="showTitSub" @focus="showTitSub"
                @mouseleave="hideTitSub" @blur="hideTitSub"
                tabindex="0"
            ></div>
        </div>

        <div class="tittle" id="tittle">
            <h1 class="aboutTab">{{ $t('ff.aboutTab') }}</h1>
            <h1 :class="['tit-sub',  { 'visible': isTitSubVisible }]">
                {{ $t('ff.AboutSab1') }}
                <br />
                {{ $t('ff.AboutSab2') }}
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isTitSubVisible: false,
        };
    },
    methods: {
        showTitSub() {
            this.isTitSubVisible = true;
        },
        hideTitSub() {
            this.isTitSubVisible = false;
        },
    },
};
</script>

<style>
.aboutTab {
    position: relative;
    padding: 0%;
}
.aboutTab .tittle {
    position: absolute;
    top: 20%; left: 0;
    padding: 0% 3%;
    text-align: left;
    color: white;
    pointer-events: none;
}
.aboutTab .tittle .aboutTab {
    font-size: 4vw;
}
.aboutTab .tittle .tit-sub{
    position: absolute;
    font-size: 3vw;
    padding: 63% 7%;
    white-space: nowrap;
    visibility: hidden;
}

.aboutTab .tittle .tit-sub.visible {
    visibility: visible;
}

.tab-photo {
    padding: 25vw;
    position: relative;
}

.tab-Backg {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    overflow: hidden;
    background-image: url('../assets/data/BackGround/Banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.tab-Backg::before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 50%; height: 100%;
    background-image: linear-gradient(to right, rgba(54, 43, 111, 0.5), rgba(133, 206, 193, 0.5));
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.tab-Backg:hover:before {
    width: 100%;
    opacity: 1;
    transition: 0.55s ease-in-out;
}
  @media (max-width: 1000px) {
    .aboutTab .tittle .aboutTab {
        font-size: 6vw;
        margin: 5vw;
        padding: 0% 0% 3.5%;
    }
    .tab-Backg {
        position: absolute;
        top: 14vw;
    }
    .tab-photo {
        height: 60vw;
    }
  }
</style>
