<template>
    <div class="project projectTab">
        <div class="tittle">
            <p class="projectTab">{{ $t('ff.projectTab') }}</p>
            <p class="projectsub">{{ $t('ff.projectsub') }}</p>
        </div>

        <div class="file" id="tabContainer" v-if="this.$i18n.locale === 'ch'">
            <ul class="nav-pills tab">
                <a
                    v-for="classification in classifications"
                    class="model"
                    :key="classification.id"
                    href="#"
                    @click.prevent="handleClick(classification)"
                    :class="{ active: this.$store.state.projectState[0] === classification.Chname,
                        'apply-hover': classification.id !== 5 && classification.id !== 6,
                        'special-background': classification.id === 5 || classification.id === 6  }"
                    :style="{ cursor: getClassificationCursorStyle(classification.id) }">
                    {{ $t(classification.Chname) }}
                </a>
            </ul>
        </div>
        <div class="file" id="tabContainer" v-if="this.$i18n.locale === 'en'">
                <ul class="nav-pills tab">
                    <a
                        v-for="classification in classifications"
                        class="model"
                        :key="classification.id"
                        href="#"
                        @click.prevent="handleClick(classification)"
                        :class="{
                            active: this.$store.state.projectState[0] === classification.Chname,
                            'apply-hover': classification.id !== 5 && classification.id !== 6,
                            'special-background': classification.id === 5 || classification.id === 6
                        }"
                        :style="{ cursor: getClassificationCursorStyle(classification.id) }">
                        {{ $t(classification.Enname) }}
                    </a>
                </ul>
            </div>
    </div>
</template>

<script>

import { mapMutations } from 'vuex';

export default {
    data() {
        return {
            classifications: [],
            startX: 0,
            currentLanguage: this.$i18n.locale,
        };
    },
    mounted() {
        const tabContainer = document.getElementById('tabContainer');
        tabContainer.addEventListener('touchstart', this.handleTouchStart);
        tabContainer.addEventListener('touchmove', this.handleTouchMove);
        tabContainer.addEventListener('touchend', this.handleTouchEnd);
        fetch('./json/projectTab.json')
            .then((response) => {
                if (response.ok) {
                return response.json();
                }
                throw new Error('Failed to fetch data.json');
            })
            .then((data) => {
                this.classifications = data;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },
    methods: {
        toggleTab(id) {
            this.$store.commit('SET_PROJECTS_STATE', []);
            const classification = this.classifications.find((c) => c.id === id);
            if (classification) {
                const translatedName = this.$t(classification.Chname);
                this.$store.commit('SET_CLASSIFICATION_STATE', classification);
                this.$store.commit('SET_PROJECTS_STATE', [translatedName]);
            }
            console.log(this.$store.state.projectState[0]);
        },
        handleClick(classification) {
            if (classification.id !== 5 && classification.id !== 6) {
                this.toggleTab(classification.id);
            }
        },
        getClassificationCursorStyle(id) {
            return (id === 5 || id === 6) ? 'default   ' : 'pointer';
        },
        tabChange(index, data) {
            this.active = index;
            this.$nextTick(() => {
                this.setCentert();
            });
        },
        setCentert() {
            const container = document.querySelector('.nav-pills tab');
            const activeNode = document.querySelector('.model.active');
            const scrollWidth = activeNode.offsetLeft
                - (document.body.clientWidth - activeNode.clientWidth) / 2;
            container.scrollLeft = scrollWidth;
        },
    },
};
</script>

<style>
.projectTab {
    padding: 6% 0% 0%;
    font-size: 2vw;
}
.projectTab .tittle {
    padding: 0% 2.5%;
    text-align: left;
    color: rgb(255, 255, 255);
}
.projectTab .tittle .projectTab{
    font-weight: bold;
}
.projectsub{
    font-size: 1vw;
    margin: 1% 2%;
    color: #ffffffc2;
}
.file {
    padding: 0.85% 1.85%;
    height: 100%;
}
.file ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
}
.file .nav-pills {
    margin: 0.5%;
}
.file .nav-pills a {
    flex: 1;
    text-align: center;
    padding: 0.5vw;
    text-decoration: none;
    color: #7B6CC6;
    font-size: 1.4vw;
    border-bottom: 0.05vw solid #7B6CC6;
}

.file .nav-pills a.active {
    color: rgb(255, 255, 255);
    background-color: #362B6F;
    font-weight: bold;
    border-bottom: 0px solid #7B6CC6;
    border-left: 0.1vw solid #7B6CC6;
    border-right: 0.1vw solid #7B6CC6;
    border-top: 0.1vw solid #7B6CC6;
}

.file .nav-pills a.apply-hover:hover {
    cursor: pointer;
    background-color: #7B6CC6;
    color: rgb(252, 252, 252);
}
.file .nav-pills a.special-background {
    background-color: #111110;
    color: #9B9B95;
}

/*.file a.active:hover {
    color: white;
    background-color: #362B6F;
    border-bottom: 0px solid #9C91D4;
    border-left: 1px solid #9C91D4; border-right: 1px solid #9C91D4; border-top: 1px solid #9C91D4;
}*/
.content{
    margin: -5% 0% 0% 0%;
}
@media (max-width: 1000px) {
    .file{
        margin: 0.5%;
        width: 100%;
        overflow: hidden;
        overflow: auto;
        white-space: nowrap;
    }
    .file a {
        padding: 2vw;
    }
    .file ul {
        display: block;
        justify-content: space-between;
        list-style: none;
        padding: 0;
    }
    .file .model{
        display: inline-block;
        margin: 0.5%;
        width: 150px;
        font-size: 3vw;
    }
    .projectTab .tittle {
        padding: 6% 2.5% 3% 2.5%;
    }
    .projectsub {
        margin: 1% 6%;
        font-size: 3.5vw;
    }
    .projectTab{
        font-size: 6vw;
        font-weight: bold;
    }
    .file .nav-pills a {
        font-size: 4vw ;
    }
    ::-webkit-scrollbar{
        display: none;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 0.25rem;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .content {
        background-color: #362B6F;
        width: 100%;
        overflow: hidden;
        overflow: auto;
        white-space: nowrap;
        margin: -4% 0% 0% 0%;
    }
    .item {
        display: inline-block;
        background-color: beige;
        margin: 0.5%;
        width: 33%;
    }
}
</style>
