<template>
    <div class="HomeC">
            <img width="3200" height="3201"
                        id="Photo1" src="../assets/data/Home/C/01.png" alt="Photo 1">

            <div class="rightTitle">
                <p class="Text"> {{ $t('ff.HomeCText') }} </p>
                <h1 class="Tittle">{{ $t('ff.HomeCTittle') }}</h1>

                <div class="Cont">
                    <h1 class="Title01">{{ $t('ff.HomeCTittle01') }}</h1>
                    <p class="Text01">{{ $t('ff.HomeCText01') }}</p>
                </div>

                <div class="Cont">
                    <h1 class="Title02">{{ $t('ff.HomeCTittle02') }}</h1>
                    <p class="Text02">{{ $t('ff.HomeCText02') }}</p>
                </div>

                <div class="Cont">
                    <h1 class="Title03">{{ $t('ff.HomeCTittle03') }}</h1>
                    <p class="Text03">{{ $t('ff.HomeCText03') }}</p>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.2/vanilla-tilt.min.js';
        script.async = true;
        document.body.appendChild(script);
    },
};
</script>

<style>
.HomeC {
    padding: 11vw 0vw 0vw 0vw;
    display: flex;
    justify-content: space-between;
}

.HomeC .Tittle{
    text-align: left;
    width: 38vw;
    margin: 0vw 0vw 0vw 2vw;
    font-size: 2.3vw;
}
.HomeC .rightTitle{
    margin: 0vw 13vw 0vw 0vw;
    height: 5vw;
}
.HomeC .rightCont .Cont{
    padding: 0% 0 1% 0%;
}

.HomeC #Photo1{
    width: 25vw;
    height: 25vw;
    margin: 0vw 0vw 0vw 17vw;
}

.HomeC .Text {
    position: absolute;
    font-size: 1.1vw;
    margin: 0vw 0vw 0vw 2vw;
    text-align: left;
    color: #85CEC1;
}

.HomeC .Title01,.HomeC .Title02,.HomeC .Title03{
    position: inherit;
    width: 20vw;
    left: 40vw;
    top: -13vw;
    color: #7B6CC6;
    font-size: 1.5vw;
    text-align: left;
    margin: 1vw 0vw 0.5vw 2vw;
}

.HomeC .Text01,.HomeC .Text02,.HomeC .Text03{
    position: inherit;
    width: 36vw;
    left: 41vw;
    top: -13vw;
    text-align: left;
    font-size: 1vw;
    font-weight: normal;
    margin: 0vw 0vw 1vw 2vw;
}

@media (max-width: 1000px) {
    .HomeC {
        position: relative;
        height: 156vw;
        padding: 13% 0% 0% 0%;
    }
    .HomeC .Text {
        position: relative;
        font-size: 3.5vw;
        left: 4vw;
    }
    .HomeC #Photo1{
        position: relative;
        left: 6vw;
        width: 50vw;
        height: 50vw;
        top: -2vw;
    }
    .HomeC .rightCont{
        position: relative;
        top: 60vw;
        left: 4vw;
        width: 80vw;
    }
    .rightGroup {
        left: -46vw;
    }
    .HomeC .rightTitle{
        position: relative;
        left: -59vw;
        top: 50vw;
    }
    .HomeC .rightTitle .Tittle {
        position: relative;
        width: 83vw;
        font-size: 4.5vw;
    }
    .HomeC .Title01, .HomeC .Title02, .HomeC .Title03 {
        width: 50vw;
        left: 40vw;
        font-size: 5vw;
    }
    .HomeC .Text01, .HomeC .Text02, .HomeC .Text03 {
        width: 80vw;
        font-size: 3.5vw;
        margin: 2vw 0vw 2vw 2vw;
    }
}
</style>
