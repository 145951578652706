import { createApp, Vue } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import en from './locales/en.json';
import ch from './locales/ch.json';

const i18n = createI18n({
    legacy: false,
    locale: 'ch',
    messages: {
      ch,
      en,
    },
});

createApp(App).use(store)
.use(router).use(i18n)
.mount('#app');
